import Seo from 'src/components/seo/Seo'

import SandeverBeachTenis from '../../components/Campanha/SandeverBeachTenis'

function SandeverBeachTenisPage() {
  return (
    <>
      <Seo title="Campanha | Decathlon" />
      <SandeverBeachTenis />
    </>
  )
}

export default SandeverBeachTenisPage
